<template>
    <error-content
      image-file-name="notfound-robo"
    ></error-content>
</template>

<script>

import ErrorContent from '@/views/Fehlerseiten/Fehler.vue'

export default {
    name: 'NotFound',
    components: { ErrorContent },
    metaInfo: {
        title: 'Page not found',
    },
}
</script>