<template>
    <v-container
      fill-height
      fluid
    >
        <v-row
          align="center"
          justify="center"
        >
            <v-col>
                <v-card
                  elevation="0"
                  class="pa-15 mx-auto"
                  max-width="550"
                >
                    <v-img
                      contain
                      :src="require('@/assets/images/fehler/' + imageFileName + '.svg')"
                      :lazy-src="require('@/assets/images/fehler/' + imageFileName + '.svg')"
                      max-width="550px"
                    >

                        <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                            </v-row>
                        </template>

                    </v-img>
                </v-card>
            </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
            <v-col
              cols="auto"
            >
                <v-btn
                  outlined
                  dark
                  large
                  :color="$store.getters.getColors.secondary2"
                  @click="$store.getters.isAuthenticated ? $router.go(-1) : $router.push({path: '/login'})"
                >
                    {{ $store.getters.isAuthenticated ? 'Back' : 'Login' }}
                    <v-icon
                      right
                      dark
                    >
                        {{ $store.getters.isAuthenticated ? 'mdi-exit-run' : 'mdi-account-lock-outline' }}
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: 'ErrorContent',
    props: {
        imageFileName: String
    },
}
</script>
